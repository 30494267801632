.lobby-header {
  background-color: white;
  border-bottom: 1px solid #ddd;

  .navbar {
    @media (min-width: 769px) {
      height: 100%;
    }

    a {
      text-decoration: none;
    }
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .--custom-container {
    height: 100%;
    margin: 0 auto;
    max-width: 1456px;
    padding: 0 40px;

    @media (max-width: 576px) {
      padding: 0 16px;
    }
  }

  .divider {
    width: 2px;
    height: 20px;
    margin: 0 10px;
    background-color: #ddd;
  }

  .logo {
    width: 120px;
    height: 60px;
    object-fit: contain;
  }
}
