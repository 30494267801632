.home-page {
  &__wrapper {
    .search-container {
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
      position: relative;

      .search-icon {
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        padding-bottom: 2px;
      }

      input {
        padding-left: 36px;
        height: 48px;

        &:hover,
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
    &.--loading {
      display: flex;
      justify-content: center;
      margin-top: 20vh;
    }
  }

  &__tabs {
    &-container {
      margin-top: 42px;
      @media (max-width: 576px) {
        margin-top: 24px;
      }
    }

    &-item {
      &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 3px;
        font-size: 14px;
        cursor: pointer;
        span {
          margin-top: 2px;
        }
      }
    }
  }
}
