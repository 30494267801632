.nav-language {
  >a {
    display: flex;
    align-items: center;
  }

  .dropdown-item:active {
    background-color: none;
  }

  .flag-dropdown {
    display: flex;
    align-items: center;
  }

  .flag {
    object-fit: cover;
    width: 24px;
    height: 18px;
    margin-right: 5px;
  }
}