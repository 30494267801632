.notfound-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .image {
    width: 320px;
  }

  button {
    a {
      color: white;
      text-decoration: none;
    }
  }
}
