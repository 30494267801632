.home-page-container {
  margin: 42px auto;
  max-width: 1456px;
  padding: 0 40px;

  @media (max-width: 576px) {
    padding: 0 16px;
    margin: 24px auto;
  }

  .row {
    --bs-gutter-y: 1.25rem;

    & > * {
      @media (max-width: 576px) {
        --bs-gutter-y: 0.5rem;
        padding: 0 4px;
      }
    }
  }
}
