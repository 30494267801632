.lobby-card-container {
  border-radius: 8px !important;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s ease;
  height: 100%;
  min-height: 316px;
  @media (max-width: 576px) {
    min-height: 160px;
  }
  &:hover,
  &:focus {
    outline: none;
    box-shadow: 0 10px 28px 0 rgba(0, 0, 0, 0.6) !important;

    .card {
      .card-body {
        bottom: 0;
      }
    }
  }

  .card {
    position: relative;
    border: none;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0)
        linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.4) 80%);
      z-index: 0;
    }

    .card-title {
      @media (max-width: 576px) {
        font-size: 16px;
        margin-bottom: 18px;
      }
      @media (max-width: 290px) {
        font-size: 12px;
      }
    }

    .card-text {
      @media (max-width: 576px) {
        display: none;
      }
    }

    .card-body {
      position: absolute;
      color: white;
      bottom: -50px;
      font-size: 12px;
      transition: all 0.5s ease;
      width: 100%;
      @media (max-width: 576px) {
        font-size: 8px;
        padding: 10px 8px;
      }

      button {
        width: 100%;
        font-size: 12px;

        @media (max-width: 290px) {
          font-size: 8px;
        }
      }
    }
    img {
      object-fit: cover;
    }
  }
}

.lobby-card-label-container {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  @media (max-width: 576px) {
    top: 8px;
    left: 8px;
  }
}

.lobby-card-label-container > *:not(:last-child) {
  margin-bottom: 6px;
  @media (max-width: 576px) {
    margin-bottom: 4px;
  }
}

.lobby-card-label {
  font-size: 12px;
  z-index: 1;
  font-weight: 900;
  color: white;
  position: relative;
  width: 50px;
  height: 28px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  font-family: 'Nunito', sans-serif;

  @media (max-width: 576px) {
    width: 40px;
    height: 22px;
    font-size: 10px;
  }
}

.new-label {
  background: #e01818;
  background-image: linear-gradient(127deg, #f72020 0%, #ce1212 80%);
  box-shadow: 0 0 15px 5px #e01818;
  animation: new-glow 2s infinite ease;
}

.hot-label {
  background: #ff4e01;
  background-image: linear-gradient(127deg, #ff7a00 0%, #ff3d00 70%);
  box-shadow: 0 0 15px 5px #ff4e01;
  animation: hot-glow 2s infinite ease;
}

/* animates glow */
@keyframes hot-glow {
  0%,
  100% {
    box-shadow: 0 0 5px 2px #ff7a00;
  }
  50% {
    box-shadow: 0 0 15px 4px #ff7a00;
  }
}

/* animates glow */
@keyframes new-glow {
  0%,
  100% {
    box-shadow: 0 0 5px 2px #e01818;
  }
  50% {
    box-shadow: 0 0 15px 4px #e01818;
  }
}
